<template>
  
   <div class="content">
       
         <div class="main">
           
            <div class="main-r">
                <h2 class="h2-1">
                    订单详情
                </h2>
                <div class="time1">
                    <div class="time1-l">
                        <span>下单时间：</span>
                        <span>{{list.createDate}}</span>
                    </div>
                    <div class="time1-c">
                        <span>订单号：</span>
                        <span>{{list.orderSn}}</span>
                    </div>
                    <div class="time1-r">
                        <span>订单状态：</span>
                        <span class="span2">{{list.state | fil2}}</span>
                    </div>
                </div>
                
                <div class="wrap">
                    <div class="div1">
                    {{changeType(list.state)}}
                    </div>
                </div>
                
                <div class="div2">订单信息</div>
                <ul class="ul1">
                    <li class="first-li">
                        <span>订单金额：</span>
                        <span class="teshu">￥{{list.totalAmount}}</span>
                    </li>
                    <!-- <li>
                        <span>订单类型：</span>
                        <span>{{list.orderType | fil1}}</span>
                    </li> -->
                    <li>
                        <span>收货信息：</span>
                        <span>{{addressFil(list.address)}}</span>
                    </li> 
                    <li>
                        <span>支付方式：</span>
                        <span v>{{payType(list.payType)}}</span>
                    </li>
                    <li v-if="list.payType==1 || list.payType==2">
                        <span>付款时间：</span>
                        <span>{{list.payDate}}</span>
                    </li>  
                    <li v-if="list.deliveryType!=undefined">
                        <span>配送方式：</span>
                        <span>{{deliveryTypeFil(list.deliveryType)}}</span>
                    </li>
                    <li v-if="list.logisticsCompany!='' &&list.trackingNumber!='' &&list.deliveryTime!=''">
                        <span>物流信息：</span>
                        <span>{{list.logisticsCompany}}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span>运单号：{{list.trackingNumber}}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span>发货时间：{{list.deliveryTime}}</span>
                    </li>   
                </ul>

                <div class="div3">{{list.storeShortName}}</div>
                <div class="div4">
                   <div class="div4-1">
                        <div class="div4-1-left">产品</div>
                        <div class="div4-1-center">发起时间</div>
                        <div class="div4-1-right">报价</div>
                    </div>

                     <ul class="ul2" v-for="(item1,i) in list.orderItemList" :key="i">
                        <li>
                            <div class="ul2-lil">
                                <div class="ul2-lil-img">
                                <template v-if="list.orderType === 3">
                                    <img src="https://imag.kqwad.com/upload/4b189f6ac9de45a6b0637140056fccd6.png" alt="" />
                                </template>
                                <template v-else>
                                    <img :src="item1.logoUrl | imagePath" alt="">
                                </template>
                                </div>
                                <div class="ul2-lil-p">{{item1.productName}}</div>
                            </div>
                             <div class="ul2-lic">{{list.createDate}}</div>
                             <div class="ul2-lir">￥{{item1.price}}</div>
                        </li>
                    </ul>
                </div>

                 <div class="fileWrap" v-if="list.fileUrl!=''&&list.orderMaterielTo==undefined">
                    <p class="tit">业务单</p>
                    <div class="listWrap">
                        <img src="../../../assets/icon/yasuo.png" alt="">
                        <div>
                        <a :href="''+hrefs+''">下载文件包</a>
                        </div>
                    </div>
                </div>
                <div class="fileWrap" v-if="list.fileUrl==''&&list.orderMaterielTo!=undefined">
                    <p class="tit">业务单</p>
                    <div class="listWrap">
                        <img src="../../../assets/icon/wenben.png" alt="">
                        <div>
                        <div @click="submit()" class="subBtn">下载业务单</div>
                        </div>
                        <!-- <div class="renew" @click="goUploadBusiness(orders.orderSn,orders.totalAmount,1)">修改业务单</div> -->
                        <!-- name:'uploadBusiness', params:{id:scope.row.orderSn,price:scope.row.price,flag:1} -->
                    </div>
                </div>
                
                <div class="myNeeds" v-show="list.iNeed!=''">
                        <p>我需要</p>
                        <div>{{list.iNeed}}</div>
                </div>
                <div class="myNeeds" v-show="list.remark!=''">
                        <p>给卖家留言</p>
                        <div>{{list.remark}}</div>
                </div>
                

            <div class="div5">
<!--                    <span>中标金额：￥{{list.totalAmount}}</span>-->
                    <span>订单总金额（不含运费）</span>
                    <span style="color:#ff0036;">￥{{list.totalAmount}}</span>
                    <span class="price">
                    </span>
                <template v-if="list.state === 0">
                    <button class="btn1" @click="immediatePayment(list.orderSn)">立即支付</button>
                </template>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import * as api from '@/api/api';
    import Vue from 'vue';
    import Aside from "@/components/common/aside.vue";
    
    Vue.filter('fil1',function(item){//item就是上方的状态码
		if(item==0){
			return '系统定价'
		}
		if(item==1){
			return "在线议价"
		}
		
		if(item==2){
			return '比价订单'	
        }
        if(item==3){
			return '需求订单'	
        }
    })
    Vue.filter('fil2',function(item){//item就是上方的状态码
		if(item==0){
			return '待付款'
		}
		if(item==1){
			return "制作中"
		}
		if(item==2){
			return '待收货'	
        }
        if(item==3){
			return '交易完成'	
        }
        if(item==4){
			return '交易关闭'	
        }
         if(item==5){
			return '退款中'	
        }
	})
    export default {
        name: "index",
        components: {Aside},//注册组件
        data() {
            return {
                hrefs:"", //下载文件地址
                filePath:"", //文件本地服务器路径
                num1: 5,
                show:true,
                shows:false,
                info:"20",
                orderSn:this.$route.params.id, //购买订单需求号参数
                list:[]

            }
        },
        methods:{
            submit(){
            // 导出excel
            this.$confirm('您确定下载导出该业务单吗?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    // console.log(this.orderSn)
                    let params={orderSn:this.orderSn}
                        this.$http.get('/excel/export',{"params":params,responseType: 'blob'}).then(res => {
                        let blob = new Blob([res.data], {type: "application/vnd.ms-excel"});
                        let url = window.URL.createObjectURL(blob);
                        window.location.href = url;
                    })
                    // return;
                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
                });
        },
            deliveryTypeFil(str){
                return str === '' ? "无需配送" : str === 0 ? "物流快递" : str === 1 ? "买家自提" : str === 2 ? "商家配送/上门服务" :  "虚拟商品/无需物流";
            },
            addressFil(str){
                return str == '' ? "无需物流" : str;
            },
            payType(str){
                return str === 0 ? "未支付" : str === 1 ? "支付宝" :"微信";
            },
            immediatePayment(orderSn) {
                this.$router.push({name: 'paystatus', params: {orderSn}});
            },
            changeType(str) {//通过状态码改变内容，在上方调用
                return str === 0 ? "您的订单还未付款，为了避免订单失效，请及时付款哦~" : str === 1 ? "订单正在制作中，制作中请与商家积极沟通制作事宜" : str === 2 ? 
                "您的订单货品已发货，请关注物流信息及时查收~" : str === 3 ? "您已确认收货，交易成功！" : str === 4 ? "重新购买" : '您已取消订单，可重新进行购买';
            },
            showCont(){
                this.show==true,
                this.shows=false
            },
            showConts(){
                this.show==false,
                this.shows=true
            },
            orderDetail() { //订单详情数据调用
                let self = this;
                let id=this.$route.params.id;
                this.orderSn=id;
                // console.log(id)
                let searchParams = {orderSn :self.orderSn}
                api.orderDetail(searchParams).then(res => {
                    
                    let list = res.data.data;
                    self.list=list;


                    self.hrefs = "https://imag.kqwad.com/"+res.data.data.fileUrl;
                    self.filePath = "https://imag.kqwad.com/"+res.data.data.filePath;
                    // console.log(res.data.data,999)
                });
            },
        },
        mounted() {
            this.orderDetail();
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../assets/scss/common";
    h2,ul,li{
        margin:0;
        padding:0
    }
    a{
        text-decoration: none;
    }
    li{
        list-style: none;
    }
    .content{ 
        background:#f5f5f5;
        .main{
            margin:0 auto;
            .main-l{
                width: 181px;
                height: 1129px;
                background:#fff;
                float: left;
            }
            .main-r{
                width: 1000px;
                // padding:
                background: #fff;
                float: right;
                .el-tabs__nav{
                    margin-left: 20px;
                    
                }
                .el-tabs__item{
                    border:1px solid red !important;
                }
                .h2-1{
                    width:962px;
                    height: 55px; 
                     
                    border-bottom: 1px solid #e5e5e5;
                    margin:0 auto;
                    margin-top: 10px;
                        
                    line-height: 55px;
                    font-size: 18px;
                }
                .time1{
                    width: 962px;
                    height: 55px;
                    line-height: 55px;
                    margin: 0 auto;
                    margin-top: 4px; 
                    font-size: 14px;
                    color:#999999;
                    >div{
                        float:left;
                        margin-left: 30px;
                    }
                    .time1-l{
                         margin: 0;
                    }
                    .time1-r{
                         .span2{
                             color:#333333;
                         }
                    }
                }
                .wrap{
                     width: 961px;
                    height: 50px;
                    background: #e6f7f0;
                    margin:0 auto;  
                    z-index: 3;    
                    background: #f5f5f5;
                }
                .div1{
                    padding-left: 37px;
                       margin-left: 18px;
                    width: 927px;
                    height: 50px;
                    background:url("../../../assets/image/jinggao.png") no-repeat 10px 18px;
                    line-height: 50px;
                    color: #d82229;
                    font-size: 15px;
                    padding-left: 37px;
                }
                .div2,.div3{
                    width: 962px;
                    height: 50px;
                    font-size: 16px;
                    line-height: 16px;
                    margin:0 auto;
                    margin-top: 23px;
                    height: 43px;
                }
                .div3{
                    padding-left: 51px;
                    background: url("../../../assets/image/fangwu.png") no-repeat 20px 1px;
                }
                .div4{
                    width: 960px;
                    border: 1px solid #e5e5e5;
                     margin:0 auto;
                    .div4-1{
                        width: 960px;
                        height:44px;
                        background: #f5f5f5;
                        margin:0 auto;
                        font-size: 15px;
                        line-height: 44px;
                        color: #656565;
                        .div4-1-left{
                            text-align: center;
                            width:312px;
                            float:left;
                        }
                        .div4-1-center{
                            
                            width:370px;
                            text-align: center;
                            float:left;
                        }
                         .div4-1-right{
                            width:202px;
                            text-align: center;
                            float:left;
                        }

                    }
                    .ul2{
                        margin:0 auto;
                        width: 960px;
                            height:120px;
                   
                        li{
                            width: 960px;
                            height:120px;
                            border-bottom: 1px solid #e5e5e5;
                            .ul2-lil{
                                
                                width: 312px;
                                height: 100%;
                                float: left;
                                .ul2-lil-img{
                                    margin-left: 17px;
                                    margin-top: 20px;
                                    width: 80px;
                                    height: 100px;
                                img{
                                    display: block;
                                    width: 80px;
                                    height: 80px;
                                }
                             }
                             .ul2-lil-p{
                                font-size: 15px;
                                width: 195px;
                                overflow:hidden;
                            text-overflow:ellipsis;
                            white-space:nowrap;
                                color:#333;
                                margin:17px 0 0 20px;
                            }
                               
                            >div{
                                float:left;
                                 }
                            }
                            .ul2-lic{
                                width: 370px;
                            
                                float:left;
                                text-align: center;
                                margin-top: 20px;
                            }
                            .ul2-lir{
                                width: 202px;
                                color:#ff0036;
                                float:left;
                                text-align: center;
                                margin-top: 20px;
                            }
                        }
                    }
                     
                }
                .fileWrap{
                    width: 960px;
                    margin: 0 auto;
                    margin-top: 20px;
                .tit{
                    margin: 15px 0 10px 0;
                }
                    .listWrap{
                    display: flex;
                    border:1px solid #e5e5e5;
                    align-items: center;
                    padding: 10px;
                    >:nth-child(2){
                        margin-left: 15px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        font-size: 14px;
                        color:#666;
                        :first-child{
                        cursor: pointer;
                        }
                        :last-child{
                        color:deepskyblue;
                        cursor: pointer;
                        }
                    }
                    >:nth-child(3){
                        margin-left: auto;
                        margin-right: 30px;
                        background: deepskyblue;
                        color: #fff;
                        padding: 5px 10px;
                        height: 20px;
                        font-size: 14px;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                    }
                
                }
                .myNeeds{
                    
                    width: 100%;
                    padding:0 20px;
                    
                    p{
                        font-size: 16px;
                        margin: 20px 0 10px 0;
                    }
                    div{
                        min-height: 46px;
                        font-size: 14px;
                        border: 1px solid #e5e5e5;
                        padding:10px 10px;
                        line-height: 24px;
                    }
                }
                .div5{
                    width: 960px;
                    margin: 0 auto;
                    height:94px;
                    line-height: 94px;
                    color: #333;
                    text-align: right;
                        span{
                            margin-right: 30px;
                        }
                        
                    .btn1{
                         width: 100px;
                        height:36px;
                        background:#d82229;
                        border-radius: 3px;
                        border: none;
                        font-size: 15px;
                        color:#fff;
                        outline:none;
                        cursor: pointer;
                    }
                    .price{
                        color: #ff0036;
                        font-size: 22px;
                    }
                   
                }
            }
            .ul1{
                    width: 960px;
                    // height: 130px;
                    padding-bottom: 10px;
                    border:1px solid #eaeaea;
                    margin:0 auto;
                    li{
                        height: 27px;
                        line-height:27px;
                        font-size: 15px;
                        color:#666666;
                        padding-left: 16px;
                        .teshu{
                            color:#ff264c;
                        }
                        
                    }
                    .first-li{
                            margin-top: 10px;
                        }
                }

        }
    }
   
</style>
